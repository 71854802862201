import React, { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import './DailyChart.css';
import EChartsReact from "echarts-for-react";
import dayjs from "dayjs";

const chartOptions = {
    tooltip: {
        axisPointer: {
            type: 'shadow'
        }
    },
    grid: {
        left: 1,
        right: 1,
        bottom: 60,
        top: 15,
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            axisTick: {
                show: false,
                alignWithLabel: true
            },
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                formatter: (value, index) => {
                    let result =  dayjs(value,"MM-DD-YYYY").format("MMM DD")
                    return result;
                }
            },
            axisLine: {
                show: true
            }
        }
    ],
    yAxis: [
        {
            type: 'value',
            show: true,
            splitLine: {
                show: false
            }
        }
    ],
    dataZoom: [{
        realtime: false,
        minValueSpan: 7,
        maxValueSpan: 30,
        brushSelect: false,
        right: 30,
        left: 30
    }],
    series: [
        {
            type: 'bar',
            barWidth: '60%',
            itemStyle: {
                borderRadius: [2, 2, 0, 0],
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0.5, color: '#E77272'
                        },
                        {
                            offset: 1, color: '#fff'
                        }],
                    global: false
                }
            },
            selectedMode: true,
            select: {
                itemStyle: {
                    color: "#E77272",
                }
            },
            emphasis: {
                itemStyle: {
                    borderColor: "#000",
                }
            }


        }
    ]
};
const DailyChart = (props) => {
    let echartRef = useRef(null);

    useEffect(() => {
        if (props.data && Object.keys(props.data).length) {
            let data = props.data.data || [];
            let categories = props.data.categories || [];
            let echartInstance = echartRef.current?.getEchartsInstance();
            echartInstance.on('click', 'series', handleChartClick);
            echartInstance.on('datazoom', 'xAxis.category', () => handleDataZoom(echartInstance));
            echartInstance.setOption({
                xAxis: [
                    {
                        data: categories
                    }
                ],
                series: [
                    {
                        data: data
                    }
                ],
                dataZoom: [{
                    startValue: categories[categories.length - 1],
                    endValue: categories[categories.length - 16],
                }],
            });
        }
    }, [props.data]);

    const handleChartClick = (obj) => {
        props.handleChartClick(obj.name)
    }
    const handleDataZoom = (echartInstance) => {
        let dataZoom = echartInstance.getOption().dataZoom[0] || {};
        let categories = props.data.categories || [];
        props.handeDateChange(categories[dataZoom.startValue], categories[dataZoom.endValue])
    }

    return (
        <div className="daily-report-chart">
            <div className="report-chart-header">
                <div className="report-chart-title">
                    Overall Date Range Statistics
                </div>
            </div>
            {props.loader.dailyStatics ?
                <CircularProgress style={{ color: "grey", margin: "15vh auto" }} /> :
                <EChartsReact
                    ref={echartRef}
                    style={{ height: '300px', display: "flex", flex: 1 }}
                    option={chartOptions}
                />}
        </div>)
}
export default DailyChart;            