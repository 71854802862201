import React, { useRef, useEffect, useState } from 'react';
import EChartsReact from 'echarts-for-react'; import {
    FormControl,
    TextField,
    InputAdornment,
    CircularProgress,
} from "@mui/material";
import {Search} from "@mui/icons-material";
import './LocationHourlyCharts.css';
import dayjs from 'dayjs';

const areaGraphOptions = {
    tooltip: {
        position:"relative",
    },
    grid: {
        left: '2%',
        top: '0%',
        right: '2%',
        bottom: '0%',
        height: 40,
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        show: false,
    },
    yAxis: {
        type: 'value',
        show: false,
    },
    series: [
        {
            type: 'line',
            lineStyle: {
                color: '#711865',
            },
            itemStyle: {
                opacity: 0,
                color: '#711865',
            },
            areaStyle: {
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: '#ED572E',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ],
                    global: false,
                },
            },
        },
    ],
};

const barGraphOptions = {
    tooltip: {
        axisPointer: {
            type: 'shadow',
        },
    },
    grid: {
        left: 30,
        right: 30,
        bottom: 0,
        top: 0,
        height: 60,
    },
    xAxis: [
        {
            type: 'category',
            axisTick: {
                show: false,
                alignWithLabel: true,
            },
            axisLine: {
                show: false,
            },
            axisLabel: {
                interval: 'auto',
                showMinLabel: true,
                showMaxLabel: true,
            },
        },
    ],
    yAxis: [
        {
            type: 'value',
            show: false,
        },
    ],
    series: [
        {
            name: '',
            type: 'bar',
            itemStyle: {
                borderRadius: [2, 2, 0, 0],
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: '#2D9CDB',
                        },
                        {
                            offset: 1,
                            color: '#fff',
                        },
                    ],
                    global: false,
                },
            },
            emphasis: {
                itemStyle: {
                    borderColor: '#000',
                },
            },
        },
    ],
};

const AreaGraph = (props) => {
    const lineGraph = useRef(null);
    useEffect(() => {
        if (props.data && Object.keys(props.data).length) {
            let echartInstance = lineGraph.current?.getEchartsInstance();
            echartInstance.setOption({
                xAxis: [
                    {
                        data: props.data?.categories
                    }
                ],
                series: [
                    {
                        name: props.name.date,
                        data: props.data?.data
                    }
                ],
            });
        }
    }, [props.data]);

    return <EChartsReact
        ref={lineGraph}
        style={{ height: '40px' }}
        option={props.options} />;
};

const BarGraph = (props) => {
    const barGraph = useRef(null);
    useEffect(() => {
        if (props.data && Object.keys(props.data).length) {
            let echartInstance = barGraph.current?.getEchartsInstance();
            echartInstance.setOption({
                xAxis: [
                    {
                        data: props.data?.categories
                    }
                ],
                series: [
                    {
                        name: props.name.date,
                        data: props.data?.data
                    }
                ],
            });
        }
    }, [props.data]);

    return (
        <EChartsReact
            style={{ height: '80px' }}
            option={props.options}
            ref={barGraph}
        />
    );
};

export default function LocationHourlyCharts(props) {

    const [barGraphData, updateBarGraphData] = useState({});
    const [lineGraphData, updateLineGraphData] = useState({});
    const [selectedDate, updateSelectedDate] = useState("");
    const [searchText, updateSearchText] = useState("");

    useEffect(() => {
        updateSelectedDate(props.selectedDate && props.selectedDate || "")
    }, [props.selectedDate])

    useEffect(() => {
        const { categories = [], data = {} } = props.data || {};

        let allLocationsSum = categories.map((m, i) => {
            let sum = 0;
            Object.keys(data).forEach(n => {
                sum += data[n][i];
            });
            return sum;
        });

        let tempData = {}
        Object.keys(data).forEach(n => {
            tempData[n] = { counts: data[n], total: data[n].reduce((sum, a) => sum + a, 0) }
        });

        let filteredData = {};
        if(searchText) {
            filteredData = Object.keys(tempData).reduce((acc, m) => {
                if (m.toString().toUpperCase().includes(searchText?.toUpperCase())) acc[m] = tempData[m]
                return acc;
            }, {});
        } else {
            filteredData = {...tempData}
        }
        
        updateBarGraphData({ categories, data: allLocationsSum, total: allLocationsSum.reduce((sum, a) => sum + a, 0) })
        updateLineGraphData(filteredData)
    }, [props.data]);

    const handleLocationSearch = (val) => {
        let { data = {} } = props.data || {};
        let tempData = {}
        Object.keys(data).forEach(n => {
            tempData[n] = { counts: data[n], total: data[n].reduce((sum, a) => sum + a, 0) }
        });

        let filteredData = Object.keys(tempData).reduce((acc, m) => {
            if (m.toString().toUpperCase().includes(val?.toUpperCase())) acc[m] = tempData[m]
            return acc;
        }, {});
        updateSearchText(val)
        updateLineGraphData(filteredData)
    }


    return (
        <div className="custom-hourly-container">
            <div className="location-hourly-charts">
                <div className="location-header">
                    <div className="location-title">Documents by Hour For { dayjs(selectedDate.date,"MM-DD-YYYY").format("MM/DD/YYYY")}</div>
                    <div className="location-search">
                        <FormControl>
                            <TextField
                                variant="standard"
                                style={{ width: "175px" }}
                                id="input-with-icon-textfield"
                                placeholder="Search Location"
                                name="search_cat"
                                autoComplete="off"
                                value={searchText}
                                onChange={e => handleLocationSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                </div>
                {props.loader?.hourlyLocationStatics ? <CircularProgress style={{ color: "grey", margin: "15vh auto" }} /> : <>
                    <div className="location-bar-chart">
                        <div className="chart-header">
                            <div>All Locations</div>
                            <div className="total-count">
                                <div>Total</div>
                                <div>{barGraphData.total}</div>
                            </div>
                        </div>
                        <BarGraph options={barGraphOptions} data={barGraphData} name={{date: dayjs(selectedDate.date,"MM-DD-YYYY").format("MM/DD/YYYY")}} />
                    </div>
                    <div className="location-chart-list">
                        {Object.keys(lineGraphData).map((m, i) => {
                            return (
                                <div className="location-area-chart">
                                    <div className="chart-header">
                                        <div>{m}</div>
                                        <div className="total-count">
                                            <div>Total</div>
                                            <div>{lineGraphData[m]?.total}</div>
                                        </div>
                                    </div>
                                    <AreaGraph options={areaGraphOptions} data={{ categories: props.data.categories, data: lineGraphData[m]?.counts }} name={{date: dayjs(selectedDate.date,"MM-DD-YYYY").format("MM/DD/YYYY")}} />
                                </div>
                            );
                        })}
                    </div>
                </>}
            </div>
        </div>
    );
}
