import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import * as c from "../../../utils/constants/constants";
import { FormatDate, GetCurrentDateAndTime } from "../../../commonFunctions/commonFunctions";

const getReportName = (props) => {
    let { selectedFilters = {} } = props || {};
    let finalDate = ` for between ${FormatDate({date: selectedFilters.fromDate, format: "MM/DD/YYYY"})} and ${FormatDate({date: selectedFilters.toDate, format: "MM/DD/YYYY"})}`;
    return "Document: " + c.PRODUCT_NAME + " daily usage report " + finalDate + "."
}

const getCsvTitle = (props) => {
    let { selectedFilters = {} } = props || {};
    let finalDate = `${FormatDate({date: selectedFilters.toDate, format: "MM/DD/YYYY"})} and ${FormatDate({date: selectedFilters.toDate, format: "MM/DD/YYYY"})}`;
    return "readabl_dailyusagereport_" + finalDate + ".csv"
}


const getHourlyCounts = (rawData) => {
    const { categories = [], data = {}} = rawData || {};
    let headers = {column_0: "S.no", column_1: "Date", column_2: "Total"};
    categories.forEach((m, i) => {
        headers["column_" + (i + 3)] = i == 0 ? `${m > 9 ? m : ("0" + m)}:00-${(m + 1) > 9 ? (m + 1) : ("0" + (m + 1))}:00` : `${m > 9 ? m : ("0" + m)}:00-${(m + 1) > 9 ? (m + 1) : ("0" + (m + 1))}:00`;
    });

    let finalData = Object.keys(data).map((m, i) => {
        let finalObj = {
            column_0: i + 1,
            column_1: m
        };
        let sum = 0;
        data[m].forEach((n, i) => {
            finalObj["column_" + (i + 3)] = n;
            sum += n;
        });

        finalObj["column_2"] = sum;

        return finalObj;
    });

    return {
        headers: [(headers || {})],
        data: finalData
    }
}
const getLocationDailyCounts = (rawData) => {
    const { categories = [], data = {}} = rawData || {};
    let headers = {column_0: "S.no", column_1: "Date", column_2: "Total"};
    let locations = Object.keys(data);
    locations.forEach((m, i) => {
        headers["column_" + (i + 3)] = m;
    });
    
    let finalData = categories.map((m, i) => {
        let finalObj = {
            column_0: i + 1,
            column_1: m
        }

        let sum = 0;
        locations.forEach((n, j) => {
            finalObj["column_" + (j + 3)] = data[n].counts[i];
            sum += data[n].counts[i];
        });

        finalObj["column_2"] = sum;

        return finalObj;
    })

    return {
        headers: [(headers || {})],
        data: finalData || []
    }
}

const DailyReportExport = (props) => {
    const [dummy_headers, updateDummyHeaders] = useState([]);
    const [combined_json, updateCombinedJson] = useState([]);

    useEffect(() => {
        let d_headers = [
            { label: getReportName(props), key: "column_0" },
            { label: '', key: "column_1" },
            { label: '', key: "column_2" },
        ]
        const { locationStaticsByDate = {}, hourlyStatisticsByDate = {} } = props.data || {}

        let loc_length = Object.keys(locationStaticsByDate.data || {}).length;

        let length = loc_length > 24 ? loc_length : 24 

        for (let i = 0; i < length; i++) {
            d_headers.push({
                label: "",
                key: "column_" + (i + 3)
            })
        }
        updateDummyHeaders(d_headers);

        const export_info = [
            { column_0: "Export By:", column_1: localStorage.getItem(c.USER_NAME), column_2: "" },
            { column_0: "Export Date/Time:", column_1: GetCurrentDateAndTime("MM/DD/YYYY, HH:mm:ss"), column_2: "" },
        ];

        const empty_row = [
            { column_0: "" }
        ];

        const report_end = [
            { column_0: "<End of Report>", column_1: "", column_2: "" }
        ];

        const hourlyCount = getHourlyCounts(hourlyStatisticsByDate);
        const locationCount = getLocationDailyCounts(locationStaticsByDate);
        let combined_json = [];
        combined_json = export_info
            .concat(empty_row)
            .concat([{ column_0: "Documents count by Location"}])
            .concat(locationCount.headers)
            .concat(locationCount.data)
            .concat(empty_row)
            .concat([{ column_0: "Documents count by Hour"}])
            .concat(hourlyCount.headers)
            .concat(hourlyCount.data)
            .concat(empty_row)
            .concat(report_end);
        updateCombinedJson(combined_json);
    }, [props.data])

    return (
        <div className="export-report-link">
            <div className="button-as-link" onClick={props.generateCustomDateRangeReport}>Custom Date Range Export</div>
            <CSVLink
                className="custom-date-range-export-link"
                headers={dummy_headers}
                filename={getCsvTitle(props)}
                data={combined_json}
                ref={props.csvLinkEl}
            />
        </div>
    )
}
export default DailyReportExport;