import React, { useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import './MonthlyChart.css';
import EChartsReact from "echarts-for-react";
import dayjs from "dayjs";

const chartOptions = {
    tooltip: {
        axisPointer: {
            type: 'shadow'
        },
    },
    grid: {
        left: 1,
        right: 1,
        bottom: 60,
        top: 15,
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            axisTick: {
                show: false,
                alignWithLabel: true
            },
            axisLabel: {
                showMinLabel: true,
                showMaxLabel: true,
                formatter: (value, index) => {
                    let result = dayjs(value,"MM-DD-YYYY").format("MMM DD")
                    return result;
                }
            },
            axisLine: {
                show: true
            }
        }
    ],
    yAxis: [
        {
            type: 'value',
            show: true,
            splitLine: {
                show: false
            }
        }
    ],
    series: [
        {
            type: 'bar',
            barWidth: '60%',
            itemStyle: {
                borderRadius: [2, 2, 0, 0],
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: [
                        {
                            offset: 0.5, color: '#E77272'
                        },
                        {
                            offset: 1, color: '#fff'
                        }],
                    global: false
                }
            },
            selectedMode: true,
            select: {
                itemStyle: {
                    color: "#E77272",
                }
            },
            emphasis: {
                itemStyle: {
                    borderColor: "#000",
                }
            }


        }
    ]
};
const MonthlyChart = (props) => {
    let echartRef = useRef(null);

    useEffect(() => {
        if (props.rp_graph && props.rp_graph.length) {
            let { rp_graph = [] } = props;
            let category = rp_graph.find(m => m.name == "Day");
            let data = rp_graph.find(m => m.name == "Count of Documents");
            let echartInstance = echartRef.current?.getEchartsInstance();
            let cat = (category.data || []).map(m => {
                let temp = Number(props.all_state.month)
                let tmp_mnth = temp < 9 ? `0${(temp + 1)}` : temp +1;
               return  dayjs(`${tmp_mnth}-${m}-${props.all_state.year}`,"MM-D-YYYY").format("MM-DD-YYYY")
            });

            echartInstance.setOption({
                xAxis: [
                    {
                        data: cat
                    }
                ],
                series: [
                    {
                        data: data.data
                    }
                ]
            });
        }
    }, [props.rp_graph]);
    return (
        <div className="daily-report-chart">
            <div className="report-chart-header">
                <div className="report-chart-title">
                    Doc Count Statistics
                    <div className="chart-subtitle">
                    </div>
                </div>
            </div>
            {props.loader.rp_graph ?
                <CircularProgress style={{ color: "grey", margin: "15vh auto" }} /> :
                <EChartsReact
                    ref={echartRef}
                    style={{ height: '300px', display: "flex", flex: 1 }}
                    option={chartOptions}
                />}
        </div>)
}
export default MonthlyChart;            
         