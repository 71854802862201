import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./AuditLog.css";
import Filter from "./Filter/Filter";
import SearchBar from "../../components/SearchBar/SearchBar";
import { TableContainer, Paper, Table, CircularProgress } from "@mui/material";
import TableData from './LogTable/TableData/TableData';
import TableHeader from './LogTable/TableHeader/TableHeader';
import TablePage from '../../components/TablePage/TablePage';
import * as c from '../../utils/constants/constants';
import * as p from '../../utils/parameter/parameter';
import * as msg from "../../utils/constants/messages";
import api from "../../utils/API/api";
import DialogModel from "../../components/DialogModel/DialogModel";
import { forceLogout } from "../../commonFunctions/forceLogout";
import { ConvertLocalToUtc, GetDefaultDate } from "../../commonFunctions/commonFunctions";
class AuditLog extends React.Component {
  state = {
    audit_log: '',
    startRecordNum: 0,
    recordsPerPage: 15,
    suggest_box: "",
    is_search: false,
    tableHeadData: [
      { name: "User Name", order: true, icon: false },
      { name: "Component", order: true, icon: false },
      { name: "Document Name", order: true, icon: false },
      { name: "Document Old Name", order: true, icon: false },
      { name: "Action", order: true, icon: false },
      { name: "Updated on", order: false, icon: true },

    ],
    audit_log_length: 0,
    is_changed: false,
    error_filter: '',
    search_val: '',
    refresh_list: false, //overlay loader applies whenever any filter is applied on screen 
    isLoading: true, //loader applies on initial load of data
    /*this object contains all filter fields, entire object is retained and filter is passed to endpoint to fetch results*/
    audit_filter: {
      pageNum: 0,
      user_name: "",
      fromDate: "",
      toDate: "",
      sort_order: false,
      sort_key: "Updated on",
      search: "",
      action: ""
    }
  }
  componentDidMount = () => {
    document.title = c.PRODUCT_NAME + ' - Audit';
    localStorage.removeItem(c.DOC_STATUS);
    var audit_filter = JSON.parse(localStorage.getItem("audit_filter"));
    //if filter data present in localStorage, use it to fetch results, else use sudit_filter defined in state to fetch results
    if (audit_filter) {
      this.setState({ audit_filter }, () => {
        this.fetchResults(audit_filter);
      })

    } else {

      let defaultAuditDate = GetDefaultDate({ duration: 30, type: "day", format: "MM/DD/YYYY" });
      this.setState({
        audit_filter: {
          ...this.state.audit_filter, 
          fromDate: defaultAuditDate.fromDate,
          toDate: defaultAuditDate.toDate,
          appliedFromDate: defaultAuditDate.fromDate,
          appliedToDate: defaultAuditDate.toDate,
        }
      }, () => {
        this.fetchResults(this.state.audit_filter);
      });
    }
  }
  //calls whenever page num is changed, this updated pageNum state with new page and calls api 
  handleChangePage = (event, newPage) => {
    let recordsPerPage = this.state.recordsPerPage;
    this.setState({ startRecordNum: recordsPerPage * newPage });
    this.setState({ audit_filter: { ...this.state.audit_filter, pageNum: newPage } }, () => {
      this.fetchResults(this.state.audit_filter);
    });
  };
  //calls whenever any filter field is changed
  handleChange = (event, value) => {
    let col = typeof event == "string" ? event : event.target.name;
    value = value || event.target.value;
    this.setState({ is_changed: true });
    this.setState({
      audit_filter: {
        ...this.state.audit_filter,
        [col]: (col == "fromDate" || col == "toDate") && value == "" ? this.state.audit_filter[col] : value
      }
    });
    //debouncing logic for searchbar, call api only if difference in user input is 500 ms
    if (col === "search") {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.findData("suggest");
      }, 500);
    }
  };
  //calls when apply button is clicked. 
  handleSubmit = () => {
    if (this.state.is_changed) {
      this.setState({ 
        audit_filter: {
          ...this.state.audit_filter, 
          pageNum: 0,
          appliedFromDate: this.state.audit_filter.fromDate,
          appliedToDate: this.state.audit_filter.toDate
        } 
      }, () => {
        this.fetchResults(this.state.audit_filter);
      });
    }
  };
  //calls when cancel button is clicked. 
  cancelFilter = (modal) => {
    localStorage.removeItem("audit_filter");
    //if cross icon in searchbar is cliked
    if (modal && modal === 'search') {
      this.setState({ audit_filter: { ...this.state.audit_filter, pageNum: 0, search: "" }, suggest_box: [], is_search: false }, () => {
        this.fetchResults(this.state.audit_filter);
      });
    }
    else {
      let defaultAuditDate = GetDefaultDate({ duration: 30, type: "day", format: "MM/DD/YYYY" });
      this.setState({
          audit_filter: { 
            user_name: "", 
            action: "", 
            fromDate: this.state.audit_filter.appliedFromDate,
            toDate: this.state.audit_filter.appliedToDate,
            appliedFromDate: defaultAuditDate.fromDate,
            appliedToDate: defaultAuditDate.toDate,
            pageNum: 0, 
            search: "", 
            sort_key: "Updated on", 
            sort_order: false 
          },
          is_changed: false,
          suggest_box: [],
        }, () => {
          this.fetchResults(this.state.audit_filter);
        }
      );
    }
  };
  //api call to backend
  fetchResults = (audit_filter) => {
    //turn ON overlay loader
    this.setState({ refresh_list: true });
    let from_date = audit_filter.appliedFromDate ? ConvertLocalToUtc({ date: audit_filter.appliedFromDate, format: "MM-DD-YYYY HH:mm:ss", startOf: "day" }) : "";
    let to_date = audit_filter.appliedToDate ? ConvertLocalToUtc({ date: audit_filter.appliedToDate, format: "MM-DD-YYYY HH:mm:ss", endOf: "day" }) : "";
    var query = {
      user_name: audit_filter.user_name && audit_filter.user_name,
      action_name: audit_filter.action && audit_filter.action.replaceAll('_', " "),
      from_date: from_date,
      to_date: to_date,
      search_string: audit_filter.search && audit_filter.search,
      sorting: {
        sort_column: audit_filter.sort_key && audit_filter.sort_key,
        is_asc: audit_filter.sort_order && audit_filter.sort_order,
      }
    }
    api.fetch_results_audit(query, audit_filter.pageNum, this.state.recordsPerPage).then((response) => {
      const data = response.data.data;
      if (response.status === 200) {
        const filteredData = data.map((dt) => {
          return {
            ...dt,
          };
        });
        localStorage.setItem("audit_filter", JSON.stringify(audit_filter));
        this.setState({ 
          audit_log: filteredData, 
          refresh_list: false, 
          isLoading: false ,
          audit_filter: {
            ...this.state.audit_filter,
            fromDate: audit_filter.appliedFromDate,
            toDate: audit_filter.appliedToDate,
          },
        });
        if (response.data.total_records !== "")
          this.setState({ audit_log_length: parseInt(response.data.total_records) });
      }
    }).catch((error) => {
      if (error.response.status === 401) {
        forceLogout();
      }
      else {
        this.setState({ refresh_list: false });
        window.alert(msg.api_error);
      }

    });
  }
  findData = (modal, search_val) => {
    /*================alters table data based on searched text==========*/
    if (modal === "results") {
      this.setState({ is_search: true, suggest_box: [] });
      this.setState({ audit_filter: { ...this.state.audit_filter, search: search_val, pageNum: 0 } }, () => {
        this.fetchResults(this.state.audit_filter);
      });
    } else {
      /*=================displays suggestion box based on searched txt==============*/
      var suggest = [];
      let query = {
        search_string: this.state.audit_filter.search
      }
      if (this.state.audit_filter.search.length > 2) {
        this.setState({ is_search: false });
        api.search_suggest_results_audit(query).then((response) => {
          if (response.status === 200) {
            response.data.data && response.data.data.map((data, i) => {
              suggest.push(data);
            })
            this.setState({ suggest_box: suggest });
          }
        }).catch((error) => {
          if (error.response.status === 401) {
            forceLogout();
          } else {
            this.setState({ refresh_list: false });
            window.alert(msg.api_error);
          }
        });
      } else {
        suggest = [];
        this.setState({ suggest_box: suggest, is_search: true });
      }
    }
  };
  //sorts table based table header 
  sort = (key) => {
    var sort_order = "";
    let table_head = [...this.state.tableHeadData];
    for (var i = 0; i < table_head.length; i++) {
      if (key.replace("_", " ") === table_head[i].name) {
        table_head[i].order = !table_head[i].order;
        sort_order = table_head[i].order;
        table_head[i].icon = true;
      } else table_head[i].icon = false;
    }
    this.setState({ tableHeadData: table_head, audit_filter: { ...this.state.audit_filter, sort_order: sort_order, sort_key: key, pageNum: 0 } }, () => {
      this.fetchResults(this.state.audit_filter);
    });
  };
  //takes to doc details screen
  goToIntervention = (doc_id) => {
    //making it false for the purpose of retaining filter from doc mgmt screen and doc details
    p.isFilter.isFilter = false;
    localStorage.setItem('is_filter', JSON.stringify(p.isFilter));
    localStorage.setItem(c.DOC_ID, doc_id);
    var _props = this.props;
    _props.handlePageChange(c.PAGE_MANUAL_REVIEW);
    _props.history.push(`${c.PAGE_URLS[c.PAGE_MANUAL_REVIEW]}?doc_id=${localStorage.getItem(c.DOC_ID)}`);
    localStorage.removeItem(c.PREV_ROUTE);
  };
  render() {
    return (
      <>
        <Navbar
          handlePageChange={this.handlePageChange}
          changeCurrentPage={(page) => this.setState({ currentPage: page })}
        />
        {this.state.isLoading ?
          <CircularProgress style={{ color: "grey", margin: "40vh auto" }} />
          :
          <>
            {this.state.refresh_list &&
              <CircularProgress size={30} className="common-spinner"
              />}
            <div className={this.state.refresh_list ? "block-area-common" : ""}>
              <div className="audit-log">
                <div className="titlebox">
                  <p className="page-title">Audit</p>
                </div>
                <div className='audit-flex'>
                  <Filter
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    cancelFilter={this.cancelFilter}
                    audit_filter={this.state.audit_filter}
                  />
                  <div>
                    <SearchBar
                      handleChange={this.handleChange}
                      findData={this.findData}
                      suggest_box={this.state.suggest_box}
                      cancelFilter={this.cancelFilter}
                      is_search={this.state.is_search}
                      search={this.state.audit_filter.search}
                    />
                  </div>
                </div>

                <div>
                  <TableContainer component={Paper} className='full-table'>
                    <Table stickyHeader aria-label='customized table' >
                      <TableHeader
                        sort={this.sort}
                        tableHeadData={this.state.tableHeadData}
                        audit_filter={this.state.audit_filter}
                      />
                      <TableData
                        audit_data={this.state.audit_log && this.state.audit_log}
                        goToIntervention={this.goToIntervention}
                      />
                    </Table>
                    {this.state.audit_log_length === 0 && <DialogModel />}
                  </TableContainer>
                  <TablePage
                    count={this.state.audit_log_length && this.state.audit_log_length}
                    recordsPerPage={this.state.recordsPerPage}
                    pageNum={this.state.audit_filter.pageNum}
                    handleChangePage={this.handleChangePage}
                    refresh_list={this.state.refresh_list}
                  />
                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }
}

export default AuditLog;

