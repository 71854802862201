import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  CircularProgress, 
  TextField,
  Alert
} from "@mui/material";
import {Close} from "@mui/icons-material";
import "./AddUser.css";

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      first_name: "",
      last_name: "",
      phone: "",
      active: "true",
      role: "Staff",
      email: "",
      errors: {
        first_name: false,
        last_name: false,
        phone: false,
        email: false
      }
    };
  }

  validateData = (id, val) => {
    let errors = JSON.parse(JSON.stringify(this.state.errors));
    if(!val) errors[id] = true; 
    else errors[id] = false;

    let isValidEmail = !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val?.toLowerCase()))
    if(id == "email" && isValidEmail) errors[id] = true;
    return errors;
  }

  _handleTextFieldChange = (event) => {
    let val = event.target.value;
    let id = event.target.id;
    let errors = this.validateData(id, val);

    const block = (id === 'phone' && event.target.value.length > 200);
    if(!block){
    this.props.userdetails[id] = val;
    this.setState({ [id]: val, errors });}
  } 
  
  handleSubmit = (event) => {
    event.preventDefault();
    let errors = JSON.parse(JSON.stringify(this.state.errors));
    ["first_name", "last_name", "email", "phone"].forEach(m => {
      if(!this.state[m]) {
        errors[m] = true
      }
    });
    this.setState({ errors })
    if(Object.values(errors).some(m => m)) return 0;

    const user_id = this.props.userdetails.user_id;
    let userdata = {...this.props.userdetails};
    userdata.active = JSON.parse(userdata.active);
    this.props.locationlist.forEach((loc) => {
      if(loc.is_auth) {
        userdata.location_ids.push(loc.id);
      }
    });
    delete userdata.user_id;
    if(user_id) {
      this.props.editExistingUser(userdata, user_id);
    }
    if(!user_id) {
      this.props.createNewUser(userdata);
    }
  }

  componentDidUpdate(prevProps) { 
    if(prevProps.openDialog != this.props.openDialog) {
      let errors = JSON.parse(JSON.stringify(this.state.errors));
      let userdetails = {};
      ["first_name", "last_name", "email", "phone"].forEach(m => {
        if(this.props.userdetails.user_id && !this.props.userdetails[m]) {
          errors[m] = true
        } else errors[m] = false;
        userdetails[m] = this.props.userdetails[m];
      });
      this.setState({ errors,  ...userdetails})
    }
  }
  
  render() {
    return (
      <div>
        <Dialog open={this.props.openDialog}>
          <DialogTitle className="title-bg">
            <div className="upload-title">
              <p style={{ margin: "0px", color: "white", fontSize: "16px" }}>
                {" "}
                {this.props.userdetails.user_id ? 'Edit User' : 'Add User'}
              </p>
              <Close
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  this.props.closeDialog();
                }}
              />
            </div>
          </DialogTitle>
          <DialogContent>
           { this.props.showErrorMessage ? <Alert severity="error">{this.props.errorMessage}</Alert> : null }
            <form
              ref="form"
              onSubmit={this.handleSubmit}
              onError={errors => console.log(errors)}>
              <div className="field-control">
                <FormControl className="form-control">
                  <label>First Name</label>
                  <TextField
                    id="first_name"
                    variant="outlined"
                    placeholder="First Name"
                    onChange={this._handleTextFieldChange}
                    value={this.props.userdetails.first_name}
                    error={this.state.errors.first_name}
                    helperText={this.state.errors.first_name ? "This field is required" : ""}
                  />
                </FormControl>
                <FormControl className="form-control">
                <label>Last Name</label>
                  <TextField
                      id="last_name"
                      variant="outlined"
                      placeholder="Last Name"
                      onChange={this._handleTextFieldChange}
                      value={this.props.userdetails.last_name}
                      error={this.state.errors.last_name}
                      helperText={this.state.errors.last_name ? "This field is required" : ""}
                  />
                </FormControl>
              </div>
              <div className="field-control">
                <FormControl className="form-control">
                  <label>Email ID</label>
                  <TextField
                    id="email"
                    variant="outlined"
                    placeholder="Email ID"
                    onChange={this._handleTextFieldChange}
                    value={this.props.userdetails.email}
                    error={this.state.errors.email}
                    helperText={!this.state.email && this.state.errors.email ? "This field is required" : ( this.state.errors.email ? "Please enter a valid email" : "")}
                  />
                </FormControl>
                <FormControl className="form-control">
                <label>Phone Number</label>
                  <TextField
                      id="phone"
                      variant="outlined"
                      placeholder="Phone"
                     // type="number"
                      onChange={this._handleTextFieldChange}
                      value={this.props.userdetails.phone}
                      error={this.state.errors.phone}
                      helperText={this.state.errors.phone ? "This field is required" : ""}
                  />
                </FormControl>
              </div>
              <div>
              <FormControl className="field-control radio-container"  component="fieldset" value={this.props.userdetails.role} onChange={this.props._handleRadioFieldChange}>
                <label >Role</label>
                <div className="radio-fields">
                  <RadioGroup row aria-label="role" name="role" defaultValue={this.props.userdetails.role} onChange={this.props._handleRadioFieldChange}>
                    <FormControlLabel defaultChecked={this.props.userdetails.role === 'Staff'} value="Staff" control={<Radio />} label="Staff" />
                    <FormControlLabel defaultChecked={this.props.userdetails.role === 'Admin'} value="Admin" control={<Radio />} label="Admin" />
                  </RadioGroup>
                </div>
            </FormControl>
              <FormControl component="fieldset" value={this.props.userdetails.active} onChange={this.props._handleRadioFieldChange} style={{marginLeft:"10px"}}>
                <label className="status-radio">Status</label> 
                <div className="radio-fields status-radio">
                <RadioGroup row aria-label="active" name="active" defaultValue={this.props.userdetails.active} onChange={this.props._handleRadioFieldChange}>
                    <FormControlLabel defaultChecked={this.props.userdetails.active === 'true'} value="true" control={<Radio />} label="Active" />
                    <FormControlLabel defaultChecked={this.props.userdetails.active === 'false'} value="false" control={<Radio />} label="Inactive" />
                </RadioGroup>
                </div>
            </FormControl>
            
            </div>
            {this.props.userdetails.role !== 'Admin' ?
              <FormControl component="fieldset" >
                <div className = 'icon-text'>
              <div className = 'loc-title'>Location :</div>
              <div className = 'icon-text'><p className = 'loc-action-text' onClick = {() => this.props.checkboxAction('all')}>Select all</p><p className = 'loc-action-text' onClick = {() => this.props.checkboxAction('none')}>Deselect all</p></div>
              </div>
             
              <div className="locations-box">
                {this.props.loc_loader ? <CircularProgress className = 'loc-loader' size = {15}/> : 
              this.props.locationlist &&
                this.props.locationlist.map((location, i) => (
                 
                  <FormControlLabel key = {i}
                  control={
                    <Checkbox
                      onChange={this.props._handleCheckboxChange}
                      value={location.id}
                      checked = {location.is_auth}
                      //defaultChecked={location.is_auth}
                      name={location.name}
                      color="primary"
                    />
                  }
                  label={location.name}
                />
                   
                ))} 
              </div>
            </FormControl>:
            <div>
              Admin role can access all locations.
              </div>}
            <div className="div-center">
              <button
                className="apply-button"
                type="submit"
              >
                <p className="apply-button-text">Save</p>
              </button>
              <button
                className="reset-button"
                type="button"
                onClick={() => {
                  this.props.locationlist.length = 0;
                  this.props.closeDialog();
                }}
              >
                <p className="reset-button-text">Cancel</p>
              </button>
            </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default AddUser;