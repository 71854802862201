import React, { useEffect, useState } from 'react';
import {
    FormControl,
    TextField,
    InputAdornment,
    CircularProgress,
} from "@mui/material";
import './LocationDailyTable.css';
import { ArrowLeft, ArrowRight,Search } from '@mui/icons-material';
import dayjs from 'dayjs';

export default function LocationDailyTable(props) {

    const [headers, updateHeaders] = useState([]);
    const [tableData, updateTableData] = useState({});
    const [currentIndex, updateCurrentIndex] = useState({ start: 0, end: 0 });
    const [selectedCol, updateSelectedCol] = useState();
    const [searchText, updateSearchText] = useState();

    useEffect(() => {
        if (props.data && Object.keys(props.data)?.length) {
            let { categories = [] } = props.data || {};
            updateCurrentIndex({ start: categories.length - 7, end: categories.length });
        }
    }, [props.data]);

    useEffect(() => {
        let { categories = [] } = props.data || {};
        let index = categories.findIndex(m => m == props.selectedDate);
        updateCurrentIndex({ start: (index - 4) < 0 ? 0 : (index - 4), end: index + 4 });
    }, [props.selectedDate])



    useEffect(() => {
        let { categories = [], data = [] } = props.data || {};
        let tempData = Object.keys(data).reduce((acc, m) => {
            if (!acc[m]) acc[m] = {};
            acc[m].total = data[m]["counts"].reduce((sum, a) => sum + a, 0);
            acc[m].counts = data[m]["counts"];
            return acc;
        }, {});
        Object.keys(tempData).forEach(m => {
            tempData[m].counts = tempData[m].counts?.slice(currentIndex.start, currentIndex.end + 1)
        });

        let filteredData = {};
        if (searchText) {
            filteredData = Object.keys(tempData).reduce((acc, m) => {
                if (m.toString().toUpperCase().includes(searchText?.toUpperCase())) acc[m] = tempData[m]
                return acc;
            }, {});
        } else {
            filteredData = { ...tempData }
        }
        let currentHeaders = categories.slice(currentIndex.start, currentIndex.end + 1);
        let index = currentHeaders.findIndex(m => m == props.selectedDate);
        updateHeaders(currentHeaders);
        updateSelectedCol(index)
        updateTableData(filteredData);
    }, [currentIndex]);

    const handleNavigationClick = (action) => {
        if (action == "prev") updateCurrentIndex({ start: currentIndex.start - 1, end: currentIndex.end - 1 });
        else if (action == "next") updateCurrentIndex({ start: currentIndex.start + 1, end: currentIndex.end + 1 });
    }

    const handleLocationSearch = (e) => {
        let { data = [] } = props.data || {};
        let tempData = {};
        Object.keys(data).forEach(m => {
            if (!tempData[m]) tempData[m] = {}
            tempData[m].counts = data[m].counts?.slice(currentIndex.start, currentIndex.end + 1);
            tempData[m].total = data[m]["counts"].reduce((sum, a) => sum + a, 0);
        });
        let filteredData = Object.keys(tempData).reduce((acc, m) => {
            if (m.toString().toUpperCase().includes(e.target.value?.toUpperCase())) acc[m] = tempData[m]
            return acc;
        }, {});
        updateSearchText(e.target.value)
        updateTableData(filteredData)
    }

    return (
        <div className="custom-table">
            <div className="table-container">
                <div className="main-header">
                    <div className="tb-grouped-col col-left table-title">
                        <div className="tb-col tb-col-1">Documents by Location</div>
                    </div>

                    {!props.loader?.locationStaticsByDate ? <div className="tb-grouped-col col-right table-navigations">
                        <div className={`prev ${currentIndex.start == 0 ? "disabled" : ""}`} onClick={e => currentIndex.start > 0 && handleNavigationClick("prev")}><ArrowLeft /> Prev</div>
                        <div className={`center-title`} >{(dayjs(props.selectedDateRange?.from_date,"MM-DD-YYYY").format("MMMM DD YYYY")) + " - " + (dayjs(props.selectedDateRange?.to_date,"MM-DD-YYYY").format("MMMM DD YYYY"))}</div>
                        <div className={`next ${currentIndex.end == props.data?.categories?.length ? "disabled" : ""}`} onClick={e => currentIndex.end < props.data?.categories?.length && handleNavigationClick("next")}>Next <ArrowRight /></div>
                    </div> : null}
                </div>
                {props.loader?.locationStaticsByDate ? <CircularProgress style={{ color: "grey", margin: "15vh auto" }} /> : <>
                    <div className="table-header">
                        <div className='tb-grouped-col col-left'>
                            <div className="tb-col tb-col-1">
                                <div className="location-search">
                                    <FormControl>
                                        <TextField
                                            variant="standard"
                                            style={{ width: "auto" }}
                                            id="input-with-icon-textfield"
                                            placeholder="Search Location"
                                            name="search_cat"
                                            autoComplete="off"
                                            value={searchText}
                                            onChange={handleLocationSearch}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Search />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className="tb-col tb-col-2">Total</div>
                        </div>
                        <div className='tb-grouped-col col-right'>
                            {headers.map((m, i) => (
                                <div key={i} className={`tb-col tb-col-${i + 3} ${selectedCol == i ? 'center-col' : ''}`}>
                                    {dayjs(m,"MM-DD-YYYY").format("MMM DD")}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="table-body">
                        {Object.keys(tableData).map((m, i) => {
                            return (
                                <div key={i} className={`tb-row tab-row-${i + 1}`}>
                                    <div className='tb-grouped-col col-left'>
                                        <div className="tb-col tb-col-1">{m}</div>
                                        <div className="tb-col tb-col-2">{tableData[m].total}</div>
                                    </div>
                                    <div className='tb-grouped-col col-right'>
                                        {(tableData[m].counts || []).map((n, i) => (
                                            <div key={i} className={`tb-col tb-col-${i + 3} ${selectedCol == i ? 'center-col' : '' }`}>
                                                {n}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>}
            </div>
        </div>
    );
}
